<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>

        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="链接名字:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入链接名字" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>

            </el-form>
          <div style="float: left">
            <el-button type="primary" @click="add()">新增</el-button>
          </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 50px;">
                <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                <el-table-column label="角色名字" prop="name" align="center"></el-table-column>
              <el-table-column label="角色图标" prop="icon" align="center" width="200">
                <template slot-scope="scope">
                  <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px" fit="cover" :class="`column${scope.$index}`" :src="scope.row.icon" :preview-src-list="[scope.row.icon]">
                    <div slot="error" class="image-slot">
                      <el-image fit="cover" :src="defaultImg"></el-image>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
                <el-table-column label="角色描述" prop="desc" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入链接名称"></el-input>
                </el-form-item>

                <el-form-item label="角色图标" prop="avatar">
                  <el-input v-model="form.icon" placeholder="请输入角色图标"></el-input>
                  <el-button @click.prevent="choiceImg">选择图片</el-button>
                  <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL"
                             :show-file-list="false"
                             :on-success="handleIconUploadSuccess" name="file"
                             :before-upload="beforeIconUpload"
                             :headers="roleIcon" style="padding-top: 20px;">
                    <img v-if="form.icon" :src="form.icon" class="avatar" style="width: 100px; height: 100px;"/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"
                       style="width: 100px;height: 100px; text-align: center;"></i>
                  </el-upload>
                  <span class="span_gray">建议尺寸1000*100像素</span>
                </el-form-item>

                <el-form-item label="角色描述" prop="path">
                    <el-input v-model="form.desc" placeholder="请输入角色描述"></el-input>
                </el-form-item>


              <el-form-item label="菜单权限">
                <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
                <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选</el-checkbox>
                <el-checkbox v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>
                <el-tree
                    class="tree-border"
                    :data="menuOptions"
                    show-checkbox
                    ref="menu"
                    node-key="id"
                    :default-checked-keys="roleMenuIdList"
                    :check-strictly="!menuCheckStrictly"
                    empty-text="加载中，请稍后"
                    :props="defaultProps"
                ></el-tree>
              </el-form-item>

                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            rules: {
                name: [{
                    required: true,
                    message: '链接名称不能为空',
                    trigger: 'blur'
                }],
                path: [
                    { validator: deal.checkUrl, trigger: ['blur', 'change'], required: true, }
                ],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            menuList: ['设置', '链接配置'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                // platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,

            form: {
            },
            mode: 'add',
            title: '新增',
            roleIcon: {},
            defaultImg: require('../../assets/img/zhan.jpeg'),
            ImgVisible:false,


            roleMenuList:[],   //单个角色的所有菜单的容器
            roleMenuIdList:[],   //单个角色的所有菜单id的容器
            menuExpand: false,
            menuNodeAll: false,
            menuCheckStrictly: true,
            // 菜单列表
            menuOptions: [],
            // 部门列表
            deptOptions: [],
            defaultProps: {
              children: "children",
              label: "label"
            },
        };
    },
    created() {
        this.loadData()
    },
    methods: {
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        cellstyle() {
            return 'text-align: center';
        },
        loadData() {
            request.get('/role/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

      /** 查询菜单树结构 */
      getMenuTreeSelect(id) {
        let url ='/menu/tree'
        if (id!==0){
          url +="?id="+id
        }
        request.get(url, {}).then(response => {
          if (response.code === 1) {
            this.menuOptions=[]
            let menuOptionsList=response.data.list
            for (const topKey in menuOptionsList) {
              let topMenuOne= menuOptionsList[topKey]

              let topMenuRes= {id:topMenuOne.id,label:topMenuOne.name,children:[]}
              for (const childKey in topMenuOne.son) {
                let childMenu =topMenuOne.son[childKey]

                topMenuRes.children.push( {id:childMenu.id,label:childMenu.name})
              }
              console.log(topMenuRes)
              this.menuOptions.push(topMenuRes);
            }
          } else {
            this.$message.error(response.msg);
          }

        });
      },

      getMenusOfRole(roleId){
        let url ='/role/menu/list'
        if (roleId!==0){
          url +="?id="+roleId
        }
        request.get(url, {}).then(response => {
          if (response.code === 1) {
              this.roleMenuList = response.data.list
              this.roleMenuIdList = []
              this.menuCheckStrictly = false  //避免父选项的子选项未全部勾，选显示全部子选项未
            for (const mnKey in this.roleMenuList) {
              this.roleMenuIdList.push(this.roleMenuList[mnKey].menu_id)
            }
            console.log(this.menuOptions)
              console.log(this.roleMenuIdList)
            /*this.roleMenuIdList.forEach((v) => {
              this.$nextTick(()=>{
                this.$refs.menu.setChecked(v, true ,false);
              })
            })*/
          } else {
            this.$message.error(response.msg);
          }
        });
      },
      // 查看大图
      showBigImg(index) {
        this.$nextTick(function () {
          let shows = document.getElementsByClassName(`column${index}`)
          let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
          show[0].style['display'] = 'block'
          let dom = show[0].getElementsByClassName('el-image-viewer__mask')
          dom[0].addEventListener('click', function () {
            show[0].style['display'] = 'none'
          })
        })
      },
      //选择图片上传
      choiceImg() {
        this.ImgVisible = true
      },
      //图片上传
      handleIconUploadSuccess(res, file) {
        if (res.code == 1) {
          loads.close()
          this.$set(this.form, 'avatar', res.data.uri);
        }
      },
      beforeIconUpload(file) {
        loads.open('.avatar-uploader .el-upload', '上传中')
        const _this = this
        return deal.imageFormat(file, _this)
      },
        //新增菜单
        // add() {
        //     this.mode = 'add';
        //     this.title = '新增链接配置';
        //     this.form = {
        //         state: 2,
        //     };
        //     this.addVisible = true;
        //     if (this.$refs.form) {
        //         this.$refs.form.clearValidate()
        //     }
        // },
        //编辑
        edit(index, row) {
            this.form = {}
            this.mode = 'edit';
            this.title = '编辑链接配置';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            this.form = Object.assign({}, item);
            this.addVisible = true;
            this.getMenuTreeSelect(row.id)
            this.getMenusOfRole(row.id)
        },
      //新增用户
      add() {
        this.mode = 'add';
        this.title = '新增用户';
        this.form = {
        };
        this.select = 0
        console.log(this.form)
        this.addVisible = true;
        this.getMenuTreeSelect(0)
      },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                      let menuIds = this.getMenuAllCheckedKeys();
                      this.form.menuIds=JSON.stringify(menuIds)
                      let param = Object.assign({}, this.form);
                        let url = this.mode === 'add' ? '/role/add' : '/role/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code === 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode === 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/common/route/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },



      // 树权限（展开/折叠）
      handleCheckedTreeExpand(value, type) {
        if (type == 'menu') {
          let treeList = this.menuOptions;
          for (let i = 0; i < treeList.length; i++) {
            this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
          }
        } else if (type == 'dept') {
          let treeList = this.deptOptions;
          for (let i = 0; i < treeList.length; i++) {
            this.$refs.dept.store.nodesMap[treeList[i].id].expanded = value;
          }
        }
      },
      // 树权限（全选/全不选）
      handleCheckedTreeNodeAll(value, type) {
        if (type == 'menu') {
          this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
        } else if (type == 'dept') {
          this.$refs.dept.setCheckedNodes(value ? this.deptOptions: []);
        }
      },
      // 树权限（父子联动）
      handleCheckedTreeConnect(value, type) {
        if (type == 'menu') {
          this.menuCheckStrictly = value ? true: false;
        } else if (type == 'dept') {
          this.deptCheckStrictly = value ? true: false;
        }
      },
      // 所有菜单节点数据
      getMenuAllCheckedKeys() {
        // 目前被选中的菜单节点
        let checkedKeys = this.$refs.menu.getCheckedKeys();
        // 半选中的菜单节点
        let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
        checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
        return checkedKeys;
      },
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
